<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton :color="filters ? 'dark' : 'warning'" size="sm" @click="toggleFilters" style="height: 26px; padding: 2px 5px;"><CIcon name="cil-filter"/></CButton>
          <CButton color="info" size="sm" @click="storeModal()">Nuevo Premio</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow v-if="filters">
        <CCol md="3"></CCol>
        <CCol md="3"></CCol>
        <CCol md="3"></CCol>
        <CCol md="3">
          <div role="group" class="form-group" style="margin: -10px 0px 10px">
            <label> Tipo de Puntos:</label>
            <CSelect
              :value.sync="typeSelect" 
              :options="typesOptions"
              @change="changeFilters"
            />                        
          </div>
        </CCol>
      </CRow>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #image_filename="{item}"><td class="w100 text-center">
          <img :src="item.image_filename" style="width: 100px; height: 100px;" v-if="item.image_filename" />
          <span v-else>-</span>
        </td></template>
        <template #type="{item}"><td class="w150 text-center">
          <span v-html="item.type == 'event_points' ? 'Puntos<br />para<br />Eventos' : (item.type == 'card_points' ? 'Puntos<br />para<br />Certificados' : (item.type == 'gift_points' ? 'Puntos<br />para<br />Premios' : item.type == 'wallet_points' ? 'Puntos<br />para<br />Monedero' : '-'))"></span>
        </td></template>
        <template #name="{item}"><td>
          <span style="font-weight: bold;">{{item.name}}</span><br />
          <span>{{item.description}}</span>
          <span v-if="item.city" style="font-style: italic; font-size: 12px;"><br />{{item.city}}</span>
        </td></template>
        <template #price="{item}"><td class="w150 text-center">{{item.price}}</td></template>
        <template #stock="{item}"><td class="w150 text-center">{{item.stock}}</td></template>
        <template #is_active="{item}"><td class="w100 text-center">
          <span>
            <CButton v-if="item.is_active" color="success" size="sm" @click="deactiveProcess(item.id)"><CIcon name="cil-check"/></CButton>
            <CButton v-if="!item.is_active" color="danger" size="sm" @click="reactiveProcess(item.id)"><CIcon name="cil-ban"/></CButton>
          </span>
        </td></template>
        <template #actions="{item}">
          <td class="table_actions b6">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <AwardModal ref="awardModal" @store="storeProcess" @update="updateProcess"></AwardModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import AwardModal from './AwardModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/awards';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'AwardsTable',
  components: { AwardModal, DeleteModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'image_filename', label: 'Imagen'},
          {key: 'name', label: 'Nombre'},
          {key: 'type', label: 'Tipo'},
          {key: 'price', label: 'Precio'},
          {key: 'stock', label: 'Disponiblidad'},
          {key: 'is_active', label: 'Status'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      filters: false,
      toasts: [],
      typeSelect: "",
      typesOptions: [
        {label: 'Todos', value: ""},
        {label: 'Puntos para Eventos', value: "event_points"},
        {label: 'Puntos para Certificados', value: "card_points"},
        {label: 'Puntos para Premios', value: "gift_points"},
        {label: 'Puntos para Monedero', value: "wallet_points"}
      ],
      challenge_id: 0,
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      }
    }
  },
  methods: {
    toggleFilters () {
      this.filters = !this.filters;
    },
    async changeFilters () {
      this.loading();

      let filters = this.getFilters();
      let response = await ws.getFiltered(filters);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();
    },
    getFilters(){
      let filters = {
        type : this.typeSelect
      };

      return filters;
    },
    storeModal () {
      this.$refs.awardModal.storeModal();
    },
    updateModal (item) {
      this.$refs.awardModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Premio", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deactiveProcess(id) {
      let response = await ws.deactive({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async reactiveProcess(id) {
      let response = await ws.reactive({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
